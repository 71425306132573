import { ProgressBar } from '@/components/atoms/progress-bar'
import useAllAreasQuery from '@/hooks/areaOfInterest/useAllAreasQuery'
import useUnmappedAreaQuery from '@/hooks/areaOfInterest/useUnmappedAreaQuery'
import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import useOpportunitiesWithMetricsQuery from '@/hooks/opportunity/useOpportunitiesWithMetricsQuery'
import useSegment from '@/hooks/useSegment'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useAreasAndOpportunitiesState, { LoadStep } from '@/store/useHomeStore'
import {
  Cell,
  ColumnDef,
  ColumnSort,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  OnChangeFn,
  Row,
  SortingState,
  Table,
  Updater,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  isNormalizedArea,
  isNormalizedOpportunity,
  NormalizedAreaTableData
} from './AreaOfInterestTable/AreaOfInterestTable.types'
import FlexContainer from '@/components/atoms/flex-container'
import useUser from '@/hooks/useUser'
import Checkbox from '@/components/atoms/checkbox'
import TableV2, { getCheckboxState } from '@/components/atoms/table-v2/TableV2'
import NameCell from './AreaOfInterestTable/NameCell'
import AreaOfInterestOptions from './AreaOfInterestTable/AreaOfInterestOptions'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import { ONGOING_STATUS } from '@/utils/opportunityUtils'
import useNormalizedAreas from './AreaOfInterestTable/useNormalizedAreas'
import useCollections from '@/hooks/collections/useCollections'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import TableSkeleton from '../../skeleton/TableSkeleton'
import Button from '@/components/atoms/button'
import { ArrowDown, ArrowUp } from '@phosphor-icons/react'
import { colors, CSS } from '@/theme'
import { getHeaderCss } from '../../opportunities/opportunityTableUtils'
import SaveCollectionBar from '../../collections/SaveCollectionBar'
import SaveCollectionDialog from '../../collections/SaveCollectionDialog'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import shortUUID from 'short-uuid'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useSavedFilters from '@/hooks/useSavedFilters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import { DeleteDialog } from '@/components/atoms/dialog'
import RenameAreaModal from '../RenameAreaModal'
import useNavitagateTo from '@/hooks/useNavigateTo'

const ProgressText = ({ step }: { step: LoadStep }) => {
  if (step === 'loading')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="loadingAreaList">
          <em>Loading</em> your area list...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 'ordering')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="orderingAllAreas">
          <em>Ordering</em> all areas...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 'calculating')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calculatingAllMetrics">
          <em>Calculating</em> all metrics...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  return <></>
}

const AllAreasTable = () => {
  const { track } = useSegment()
  const { t } = useTranslation()
  const { navigateTo: navigate } = useNavitagateTo()

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count:count', desc: true }])

  const expandedAreas = useAreasAndOpportunitiesState(state => state.expandedAreas)
  const setExpandedAreas = useAreasAndOpportunitiesState(state => state.setExpandedAreas)

  const setCurrentArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const { dateRange } = useDateFilterStore(state => ({
    dateRange: state.dateRange
  }))

  const { unmappedArea } = useUnmappedAreaQuery({
    enabled: true,
    loadMetrics: true
  })
  const unmappedId = useMemo(() => unmappedArea?.id, [unmappedArea])

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const selectedAreaIds = useMemo(
    () => Object.keys(rowSelection).filter(id => id !== unmappedId),
    [rowSelection, unmappedId]
  )

  const toggleSelectAll = useCallback((table: Table<NormalizedAreaTableData>) => {
    table.toggleAllPageRowsSelected(!table.getIsAllRowsSelected())
  }, [])

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const sortValues = useMemo(() => {
    return {
      sortColumn: sorting[0]?.id,
      sortDirection: sorting[0]?.desc ? 'desc' : ('asc' as 'desc' | 'asc')
    }
  }, [sorting])

  const {
    areas,
    isLoading,
    isMetricsLoading,
    isSortingMetricsLoading,
    hasMore,
    loadNextPage,
    loadStep,
    progress,
    fetchSortingMetrics,
    allAreas
  } = useAllAreasQuery()

  const { onStartBlankFilter } = useSavedFilters({ newFeed: true })

  const { setArea, deleteArea, isDeleting, renameArea, updateUseInUnmapped } = useAreaOfInterest()

  const { applyOpportunityFilters } = useAdvancedFilters()
  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)

  const { fetchOppsMetrics, opportunitiesByAreaId } = useOpportunitiesWithMetricsQuery({
    areas: areas.filter(area => area.opportunityCount > 0 && area.error === null)
  })

  const metricFilterFn = useCallback((area: NormalizedAreaTableData) => {
    return {
      context: area.originalArea?.context,
      opportunity_id: area.originalOpportunity?.id
    }
  }, [])

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<NormalizedAreaTableData>({
      filterFn: metricFilterFn
    })

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const translator = useMemo(() => shortUUID(), [])

  const onAreaClick = useCallback(
    (area: AreaOfInterestData) => {
      track('area_access', { area_name: area.name, from: 'home' })
      setArea(area)

      const shortAreaId = translator.fromUUID(area.id)
      navigate(`/area/${shortAreaId}`)
    },
    [track, setArea, translator, navigate]
  )

  const onOpportunityClick = useCallback(
    (opportunity: OpportunityItem, area: AreaOfInterestData) => {
      track('opportunity_access', { opportunity_name: opportunity.name, from: 'home' })
      setCurrentArea(area)

      applyOpportunityFilters({ opportunity, clearAllFilters: true })

      const shortOpportunityId = translator.fromUUID(opportunity.id)
      navigate(`/opportunity/${shortOpportunityId}`)
    },
    [track, setCurrentArea, applyOpportunityFilters, translator, navigate]
  )

  const onClickUpdateUseInUnmapped = useCallback(
    (area: AreaOfInterestData) => {
      updateUseInUnmapped({ area, newUseInUnmapped: !area.useInUnmappedArea })
    },
    [updateUseInUnmapped]
  )

  const [areaToDelete, setAreaToDelete] = useState<string | null>(null)

  const onDeleteAreaClick = useCallback((area: AreaOfInterestData) => {
    setAreaToDelete(area.id)
  }, [])

  const onConfirmDelete = () => {
    if (areaToDelete) {
      if (currentArea?.id === areaToDelete) {
        setCurrentArea(undefined)
        onStartBlankFilter(true)
        resetAllFilters({ keepDate: true })
      }

      deleteArea(areaToDelete)
      track('area_deleted')
    }
  }

  const onOpenDeleteChange = (open: boolean) => {
    setAreaToDelete(prevArea => (open ? prevArea : null))
  }

  const [areaToRename, setAreaToRename] = useState<AreaOfInterestData | null>(null)

  const onRenameAreaClick = useCallback((area: AreaOfInterestData) => {
    setAreaToRename(area)
  }, [])

  const onConfirmRename = (newName: string) => {
    if (areaToRename) {
      renameArea({ area: areaToRename, newName })
      setAreaToRename(null)
      if (currentArea?.id === areaToRename.id) {
        setCurrentArea({ ...currentArea, name: newName })
      }
    }
  }

  const onOpenRenameChange = (open: boolean) => {
    setAreaToRename(prevArea => (open ? prevArea : null))
  }

  const areaColumns: ColumnDef<NormalizedAreaTableData>[] = useMemo(() => {
    const columns: ColumnDef<NormalizedAreaTableData>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: ({ table }) => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} gap="nano">
            <Checkbox
              checked={getCheckboxState(table)}
              onCheckedChange={() => toggleSelectAll(table)}
              onClick={e => e.stopPropagation()}
              value="all-areas-checkbox"
            />
            <span>{t('areas')}</span>
          </FlexContainer>
        ),
        enableHiding: false,
        minSize: 400,
        maxSize: 420,
        sortDescFirst: true,
        cell: props => (
          <NameCell
            data={props.row.original}
            canExpand={props.row.getCanExpand()}
            onAreaClick={onAreaClick}
            onOpportunityClick={onOpportunityClick}
            checkboxDisabled={
              !props.row.getCanSelect() || props.row.original.originalArea?.isUnmappedArea || false
            }
            checked={props.row.getIsSelected()}
            isExpanded={props.row.getIsExpanded()}
            onCheckedChange={props.row.getToggleSelectedHandler()}
            showCheckbox={
              props.row.original.type === 'area-interest' &&
              !props.row.original.originalArea?.isUnmappedArea
            }
            toggleExpand={props.row.getToggleExpandedHandler()}
          />
        ),
        footer: props => props.column.id
      },
      ...metricColumns,
      {
        id: 'opportunityCount',
        accessorKey: 'opportunityCount',
        header: t('opportunityCount'),
        maxSize: 122,
        cell: props => {
          if (isNormalizedOpportunity(props.row.original)) {
            return <></>
          }

          return (
            <FlexContainer css={{ padding: '$xxs', fontSize: '$micro' }} justifyContent="flexEnd">
              {props.getValue<number>()}
            </FlexContainer>
          )
        },
        footer: props => props.column.id
      }
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: props => (
          <AreaOfInterestOptions
            data={props.row.original}
            onClickUpdateUseInUnmapped={onClickUpdateUseInUnmapped}
            onDeleteAreaClick={onDeleteAreaClick}
            onRenameAreaClick={onRenameAreaClick}
          />
        )
      })
    }

    return columns
  }, [
    isManager,
    metricColumns,
    onAreaClick,
    onOpportunityClick,
    onClickUpdateUseInUnmapped,
    onDeleteAreaClick,
    onRenameAreaClick,
    toggleSelectAll,
    t
  ])

  const onExpandedChange = useCallback(
    (updater: Updater<ExpandedState>) => {
      const value = updater instanceof Function ? updater(expandedAreas) : updater

      setExpandedAreas(value)

      const prevAreas = Object.entries(expandedAreas)
        .filter(([, value]) => value)
        .map(([key]) => key)

      const newAreas = Object.entries(value)
        .filter(([, value]) => value)
        .map(([key]) => key)

      const areasIdsToFetchOppsMetrics = newAreas.filter(areaId => !prevAreas.includes(areaId))
      const areasToFetchOppsMetrics = [...areas].filter(area =>
        areasIdsToFetchOppsMetrics.includes(area.id)
      )

      areasToFetchOppsMetrics.forEach(area => {
        const opportunities = opportunitiesByAreaId[area.id]
        if (!opportunities) return

        const ongoingOpps = opportunities.filter(opportunity =>
          ONGOING_STATUS.includes(opportunity.status)
        )

        fetchOppsMetrics([
          ongoingOpps.map(opportunity => ({
            context: area?.context,
            opportunity_id: opportunity.id
          })),
          ongoingOpps.map(item => item.id),
          area.id
        ])
      })
    },
    [expandedAreas, opportunitiesByAreaId, setExpandedAreas, areas, fetchOppsMetrics]
  )

  const getRowId = useCallback((row: NormalizedAreaTableData) => {
    return row.id
  }, [])

  const getRowCanExpand = useCallback((row: Row<NormalizedAreaTableData>) => {
    const { original } = row
    if (!isNormalizedArea(original)) return false

    return original.opportunityCount > 0 && original.originalArea.error === null
  }, [])

  const getSubRows = useCallback((row: NormalizedAreaTableData) => {
    return row.opportunities
  }, [])

  const onSetSorting: OnChangeFn<SortingState> = useCallback(
    updater => {
      const columnSort = typeof updater === 'function' ? updater(sorting) : updater

      const sortColumn = columnSort[0]?.id ?? 'count:count'
      const sortDirection = columnSort[0] ? (columnSort[0]?.desc ? 'desc' : 'asc') : 'desc'
      fetchSortingMetrics({
        areas: allAreas,
        sortColumn,
        sortDirection
      })
      setSorting(updater)
    },
    [fetchSortingMetrics, allAreas, sorting]
  )

  const isLoadMoreDisabled = isMetricsLoading || isSortingMetricsLoading
  const isSomethingLoading = isLoading || isMetricsLoading || isSortingMetricsLoading

  const { createCollection, currentCollection } = useCollections({ enabled: false })

  const normalizedAreas = useNormalizedAreas(
    areas,
    currentCollection === null ? unmappedArea : undefined,
    sortValues
  )

  const table = useReactTable<NormalizedAreaTableData>({
    data: normalizedAreas,
    columns: areaColumns,
    manualFiltering: true,
    manualExpanding: false,
    manualSorting: true,
    getRowId,
    getRowCanExpand,
    getSubRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange,
    onSortingChange: onSetSorting,
    enableRowSelection: true,
    enableSubRowSelection: false,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableColumnResizing: false,
    enableHiding: true,
    enableSorting: !isSomethingLoading,
    state: {
      columnVisibility,
      sorting,
      expanded: expandedAreas,
      rowSelection,
      columnPinning: {
        left: ['name'],
        right: ['options']
      }
    }
  })

  useDidUpdateEffect(() => {
    table.resetExpanded()
  }, [dateRange, hiddenMetrics])

  useDidUpdateEffect(() => {
    setSorting([{ id: 'count:count', desc: true }])
  }, [hiddenMetrics])

  const tdCss = useCallback(
    (cell: Cell<unknown, unknown>) => {
      const data = cell.row.original as NormalizedAreaTableData
      const row = cell.row

      let css: CSS = {}

      if (isNormalizedArea(data)) {
        if (row.getIsExpanded()) {
          css = {
            ...css,
            bc: '#E8E8E8',
            bBottom: '$neutralHighPure'
          }
        } else {
          const sortedRows = table.getSortedRowModel().rows
          const nextRow = sortedRows.at(row.index + 1)

          // Add a different bottom color if the next area is expanded
          if (nextRow && nextRow.getIsExpanded()) {
            css = { ...css, bBottom: '$neutralHighPure' }
          }
        }
      }

      if (isNormalizedOpportunity(data)) {
        css = { ...css, bc: '#F9F9F9' }

        const parentRow = row.getParentRow()

        // Add a different bottom color to the last opportunity of the expanded area
        // the "row" param and its parent's subRows are not sorted here, use the table to get the rows sorted
        const sortedParentRowSubRows =
          table.getSortedRowModel().rows.find(sortedRow => sortedRow.id === parentRow?.id)
            ?.subRows ?? []
        const lastSortedSubRow = sortedParentRowSubRows.at(-1)

        if (lastSortedSubRow && lastSortedSubRow.id === row.id) {
          css = { ...css, bBottom: '$neutralHighPure' }
        }
      }

      return css
    },
    [table]
  )

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const onSaveCollectionBarClick = () => {
    setIsSaveModalOpen(true)
  }

  const onCancelBarClick = () => {
    setRowSelection({})
  }

  const onSaveCollection = useCallback(
    async (name: string, ids: string[]) => {
      const filterIds = allAreas.filter(area => ids.includes(area.id)).map(area => area.filterId)

      createCollection({
        name,
        filterIds,
        description: '',
        type: 'area_interest',
        visibility: 'private'
      })

      setRowSelection({})
    },
    [createCollection, allAreas]
  )

  const headerCss = useCallback(getHeaderCss, [])

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer
          alignItems="center"
          css={{ mb: selectedAreaIds.length > 0 ? 64 : 0 }}
          fullWidth
          justifyContent="center"
        >
          <Button disabled={isLoadMoreDisabled} onClick={loadNextPage} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  return (
    <FlexContainer
      css={{ maxHeight: 'max(63.5vh, 500px)', pb: '$xxs' }}
      direction="column"
      gap="xxxs"
    >
      <ProgressBar.Container direction="column" fullWidth gap="micro" visible={isSomethingLoading}>
        <ProgressText step={loadStep} />
        <ProgressBar.Bar max={100} value={progress} />
      </ProgressBar.Container>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <TableV2
          enableSort
          footer={renderFooter()}
          sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
          table={table}
          thContainerProps={headerCss}
          tdCss={tdCss}
        />
      )}

      <SaveCollectionBar
        filterIds={selectedAreaIds}
        onCancel={onCancelBarClick}
        onSave={onSaveCollectionBarClick}
      />
      <SaveCollectionDialog
        filterIds={selectedAreaIds}
        onOpenChange={setIsSaveModalOpen}
        onSave={onSaveCollection}
        open={isSaveModalOpen}
        type="area_interest"
      />

      {areaToDelete && (
        <DeleteDialog
          cancelText={t('cancel')}
          confirmText={t('delete')}
          description={t(
            'deletingWillPermanentlyEraseThisAreaOfInterestAndAnyDataAssociatedLikeChartsAndOpportunitiesPleaseProceedWithCaution'
          )}
          isDeleting={isDeleting}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={onOpenDeleteChange}
          open={!!areaToDelete}
          title={t('deleteAreaOfInterestQuestion')}
        />
      )}
      {areaToRename && (
        <RenameAreaModal
          onOpenChange={onOpenRenameChange}
          onRename={onConfirmRename}
          open={!!areaToRename}
          previousName={areaToRename?.name ?? ''}
        />
      )}
    </FlexContainer>
  )
}

export default AllAreasTable
