import { MetricItem, MetricsRequests, RawMetric } from '@/types/metrics'
import { allMetricItems, getMetricByColumn } from '@/utils/metrics'
import useLogging from '../useLogging'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import useCollectionStore from '@/store/useCollectionStore'
import { useMemo, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { endDateParam, startDateParam } from '@/utils/date'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import MetricsService from '@/services/MetricsService'
import { OpportunityItemWithMetrics } from '@/types/opportunity/Opportunity'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import {
  INVALID_METRIC_TABLE_COLUMNS,
  OPPORTUNITY_METRICS_SUPPORTED_NAMES,
  sortByOpportunityStatus
} from '@/utils/opportunityUtils'
import { delay } from '@/utils/delay'
import { MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import DefaultErrorHandler from '@/services/DefaultError'
import AreaService from '@/services/AreaService'
import { RawAreaError } from '@/types/area/AreaRequests'

interface GetOppsMetricsParams {
  filterList: FeedbackListQueryParams[]
  sortingMetric?: MetricItem['metric']
  metricList?: MetricListPayloadItem[]
  setProgress?: (progress: number) => void
}

const PROGRESS_STEP_SIZE = 100 / 3

const useOpportunityFetchMetrics = () => {
  const { logException } = useLogging({ context: 'opportunities-fetch-metrics' })

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange
        ? { start: startDateParam(state.dateRange.start), end: endDateParam(state.dateRange.end) }
        : null,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const startDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.start
    return undefined
  }, [datePeriod, dateRange])

  const endDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.end
    return undefined
  }, [datePeriod, dateRange])

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const { areas } = useBasicAreaOfInterestQuery({
    collectionId: currentCollectionId,
    enabled: true
  })

  const filterAreasIdsPerCollection = (relations: string[]) => {
    // the context of the opps should be of the relations of areas present in the collection
    if (currentCollectionId && areas) {
      const areasIds = areas.map(area => area.id)
      return relations.filter(relation => areasIds.includes(relation))
    }

    return relations
  }

  const getMetricsToUse = (
    sortingMetric?: MetricItem['metric'],
    metricList?: MetricListPayloadItem[]
  ) => {
    if (metricList && metricList.length > 0) return metricList

    if (sortingMetric)
      return [
        {
          ...sortingMetric,
          args: sortingMetric.filter,
          include_previous_value: false
        }
      ] as MetricListPayloadItem[]

    return undefined
  }

  const mapMergedAreasErrorById = useRef<Record<string, RawAreaError>>({})

  /**
   * since the org metrics are being returned from the endpoint when a error occurrs with the area
   * we set the area error on the metrics
   */
  const getRawMetricListWithAreaError = (oppId: string, rawMetrics: RawMetric[]) => {
    const areaError = mapMergedAreasErrorById.current[oppId]
    if (!areaError) return rawMetrics

    return rawMetrics.map(rawMetric => {
      return {
        ...rawMetric,
        error: {
          message: areaError.message,
          code: areaError.code,
          field: areaError.details?.field,
          isFromArea: true
        }
      } as RawMetric
    })
  }

  const buildMetricsOppsInChunks = ({
    sortingMetric,
    metricList,
    filterList,
    chunkSize
  }: GetOppsMetricsParams & { chunkSize: number }) => {
    const metricsToUse = getMetricsToUse(sortingMetric, metricList)

    const chunks = []

    for (let i = 0; i < filterList.length; i += chunkSize) {
      chunks.push({
        filters: filterList.slice(i, i + chunkSize)
      })
    }
    return { chunks, metricsToUse }
  }

  const getOptimizedOppsMetrics = async ({ setProgress, ...params }: GetOppsMetricsParams) => {
    const { metricsToUse, chunks } = buildMetricsOppsInChunks({ ...params, chunkSize: 200 })

    if (!metricsToUse) {
      const emptyMetricsErrors = new DefaultErrorHandler(Error('No metrics to use'))
      throw emptyMetricsErrors
    }

    let completed = 0
    const promises = chunks.map(async (chunk, index) => {
      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: chunk.filters,
        metric_list: metricsToUse,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      await delay(250 * index)
      return MetricsService.opportunitiesMetrics(metricsPayload).then(result => {
        setProgress?.(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunks.length * 2)) * completed)
        completed++

        return result
      })
    })

    return Promise.all(promises)
  }

  const getOppsMetrics = async ({ setProgress, ...params }: GetOppsMetricsParams) => {
    const { metricsToUse, chunks } = buildMetricsOppsInChunks({ ...params, chunkSize: 10 })

    if (!metricsToUse) {
      const emptyMetricsErrors = new DefaultErrorHandler(Error('No metrics to use'))
      throw emptyMetricsErrors
    }

    let completed = 0
    const promises = chunks.map(async (chunk, index) => {
      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: chunk.filters,
        metric_list: metricsToUse,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      await delay(250 * index)
      return MetricsService.metrics(metricsPayload).then(result => {
        setProgress?.(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunks.length * 2)) * completed)
        completed++

        return result
      })
    })

    return Promise.all(promises)
  }

  const getMergedAreasContexts = async (opps: OpportunityItemWithMetrics[]) => {
    const [mergedContextError, mergedContextResponse] = await AreaService.getMergedAreas({
      areas: opps.map(opp => ({
        identifier: opp.id,
        areas_ids: filterAreasIdsPerCollection(opp.relations)
      }))
    })

    if (mergedContextError) {
      logException(mergedContextError, {
        message: 'Failed to fetch merged areas context for opportunities metrics'
      })
      throw mergedContextError
    }

    return mergedContextResponse.map(area => {
      if (area.error) {
        mapMergedAreasErrorById.current[area.identifier] = area.error
      }
      return area.context
    })
  }

  const buildFilterList = (
    opps: OpportunityItemWithMetrics[],
    contextData: { currentFilterContext?: string; contexts: (string | null)[] }
  ) => {
    const { contexts, currentFilterContext } = contextData

    return opps.map((opp, index) => {
      if (mapMergedAreasErrorById.current[opp.id]) return { opportunity_id: opp.id }

      return {
        opportunity_id: opp.id,
        context: currentFilterContext ?? contexts[index] ?? ''
      }
    })
  }

  const fetchOppsMetricsMutation = useMutation({
    mutationFn: async (params: {
      opps: OpportunityItemWithMetrics[]
      metricList: MetricsRequests.MetricListPayloadItem[]
      currentFilterContext?: string
      setProgress?: (progress: number) => void
    }) => {
      const { opps, metricList, currentFilterContext, setProgress } = params

      let contexts: (string | null)[] = []

      if (!currentFilterContext) {
        contexts = await getMergedAreasContexts(opps)
      }

      const filterList: FeedbackListQueryParams[] = buildFilterList(opps, {
        contexts,
        currentFilterContext
      })

      let oppsMetrics: RawMetric[][]

      const isOpportunityMetricsEndpointEnabled = metricList.every(metric =>
        OPPORTUNITY_METRICS_SUPPORTED_NAMES.includes(metric.name)
      )

      if (isOpportunityMetricsEndpointEnabled) {
        const responses = await getOptimizedOppsMetrics({ filterList, metricList, setProgress })
        const someError = responses.find(response => response[0])
        if (someError) {
          logException(someError, { message: 'Failed to fetch metrics for opportunities' })
          throw someError
        }

        const data = responses.flatMap(response => response[1]) as RawMetric[][]
        oppsMetrics = data
      } else {
        const responses = await getOppsMetrics({ filterList, metricList, setProgress })
        const someError = responses.find(response => response[0])
        if (someError) {
          logException(someError, { message: 'Failed to fetch metrics for opportunities' })
          throw someError
        }

        const data = responses.flatMap(response => response[1]) as RawMetric[][]
        oppsMetrics = data
      }

      const oppsMetricsWithHandledErrors = opps.map((opp, index) => {
        const oppMetrics = oppsMetrics[index]

        return getRawMetricListWithAreaError(opp.id, oppMetrics)
      })

      return oppsMetricsWithHandledErrors
    }
  })

  const getSortingMetric = (sortColumn?: string) => {
    const countMetric = allMetricItems.count.metric
    if (!sortColumn) return countMetric

    return INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn)
      ? countMetric
      : getMetricByColumn(sortColumn)?.metric ?? countMetric
  }

  const fetchOppsSortingMetricsMutation = useMutation({
    mutationKey: ['fetch-all-opportunities-sorting-metrics'],
    mutationFn: async (params: {
      opps: OpportunityItemWithMetrics[]
      sortColumn?: string
      sortDirection?: 'asc' | 'desc'
      customSortingMetric?: MetricListPayloadItem
      currentFilterContext?: string
    }) => {
      const { opps, sortColumn, sortDirection, currentFilterContext, customSortingMetric } = params

      let contexts: (string | null)[] = []
      if (!currentFilterContext) {
        contexts = await getMergedAreasContexts(opps)
      }

      const sortingMetric = customSortingMetric ?? getSortingMetric(sortColumn)

      let oppsMetrics: RawMetric[][]

      const filterList: FeedbackListQueryParams[] = buildFilterList(opps, {
        contexts,
        currentFilterContext
      })

      if (OPPORTUNITY_METRICS_SUPPORTED_NAMES.includes(sortingMetric.name)) {
        const responses = await getOptimizedOppsMetrics({ filterList, sortingMetric })
        const someError = responses.find(response => response[0])
        if (someError) {
          logException(someError, {
            message: `Failed to fetch metric "${sortingMetric.name}/${sortingMetric.label}" to sort opportunities`
          })
          throw someError
        }

        const data = responses.flatMap(response => response[1]) as RawMetric[][]
        oppsMetrics = data
      } else {
        const responses = await getOppsMetrics({ filterList, sortingMetric })
        const someError = responses.find(response => response[0])
        if (someError) {
          logException(someError, {
            message: `Failed to fetch metric "${sortingMetric.name}/${sortingMetric.label}" to sort opportunities`
          })
          throw someError
        }

        const data = responses.flatMap(response => response[1]) as RawMetric[][]
        oppsMetrics = data
      }

      const newOpps = opps.map((item, index) => {
        return {
          ...item,
          metrics: getRawMetricListWithAreaError(item.id, oppsMetrics[index])
        }
      })

      if (INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn ?? 'count:count')) {
        if (sortColumn === 'name') {
          newOpps.sort((a, b) =>
            sortDirection === 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
          )
        }
        if (sortColumn === 'status') {
          newOpps.sort((a, b) =>
            sortDirection === 'desc'
              ? sortByOpportunityStatus(b.status) - sortByOpportunityStatus(a.status)
              : sortByOpportunityStatus(a.status) - sortByOpportunityStatus(b.status)
          )
        }
      } else {
        newOpps.sort((a, b) => a.name.localeCompare(b.name))

        newOpps.sort((a, b) =>
          sortDirection === 'desc'
            ? (b.metrics[0]?.current_value ?? 0) - (a.metrics[0]?.current_value ?? 0)
            : (a.metrics[0]?.current_value ?? 0) - (b.metrics[0]?.current_value ?? 0)
        )
      }

      return newOpps as OpportunityItemWithMetrics[]
    }
  })

  return {
    fetchOppsMetricsMutation,
    fetchOppsSortingMetricsMutation
  }
}

export default useOpportunityFetchMetrics
