import Button from '@/components/atoms/button'
import { DropdownMenuContent } from '@/components/atoms/options-menu'
import {
  Buildings,
  ClipboardText,
  IconContext,
  Plus,
  UserList,
  UsersThree
} from '@phosphor-icons/react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import IntentionSubMenu from './intention/IntentionSubMenu'
import useFeedFilters from '@/hooks/filters/useFeedFilters'
import SourceSubMenu from './source/SourceSubMenu'
import GenericAdvancedFilterSection from './GenericAdvancedFilterSection'
import useUser from '@/hooks/useUser'
import useAccountsStore from '@/store/useAccountsStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { useQuery } from '@tanstack/react-query'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useCustomerUsersFilters from '@/hooks/filters/useCustomerUsersFilters'
import { useTranslation } from 'react-i18next'
import SentimentSubMenu from './sentiment/SentimentSubMenu'

const AddAdvancedFilterMenu = () => {
  const { customFieldsFilters, feedbackDetailsFilters, feedbackTypeFilter, isLoading } =
    useFeedFilters()
  const { t } = useTranslation()

  const { userPermissions, currentUser } = useUser()
  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  const hasAccounts = useAccountsStore(state => state.hasAccounts)
  const checkHasAccounts = useAccountsStore(state => state.checkHasAccounts)

  const { fields: accountsFilters, isLoading: accountsFiltersIsLoading } = useAccountsFilters()

  const hasCustomerUsers = useCustomerUsersStore(state => state.hasCustomerUsers)
  const checkHasCustomerUsers = useCustomerUsersStore(state => state.checkHasCustomerUsers)

  const { fields: customerUsersFilters, isLoading: customerUsersFiltersIsLoading } =
    useCustomerUsersFilters()

  const canShowCustomerRecords = hasCustomerRecordsPermission

  useQuery({
    queryKey: ['customer-records-check', { hasCustomerRecordsPermission }],
    queryFn: async () => {
      if (!hasCustomerRecordsPermission) return

      return Promise.all([
        checkHasAccounts(currentUser?.organization_id),
        checkHasCustomerUsers(currentUser?.organization_id)
      ])
    },
    retry: false,
    refetchOnMount: true,
    enabled: hasCustomerRecordsPermission
  })

  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger asChild>
        <Button css={{ mr: 'auto', pAll: 0 }} variant="link">
          <Plus weight="bold" />
          <span>{t('addFilter')}</span>
        </Button>
      </DropdownMenuPrimitive.Trigger>

      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContent
          align="start"
          css={{ width: 320, padding: '$nano', gap: 0 }}
          hideWhenDetached
          onClick={e => {
            e.stopPropagation()
          }}
          sideOffset={8}
        >
          <IconContext.Provider value={{ size: 16 }}>
            <IntentionSubMenu />
            <SentimentSubMenu />

            {!!feedbackTypeFilter && <SourceSubMenu />}

            <GenericAdvancedFilterSection
              filters={feedbackDetailsFilters}
              icon={<ClipboardText />}
              isLoading={isLoading}
              title={t('feedbackDetails')}
            />

            <GenericAdvancedFilterSection
              filters={customFieldsFilters}
              icon={<UserList />}
              isLoading={isLoading}
              title={t('customFields')}
            />

            {canShowCustomerRecords && (
              <>
                {hasAccounts && (
                  <GenericAdvancedFilterSection
                    filters={accountsFilters}
                    icon={<Buildings />}
                    isLoading={accountsFiltersIsLoading}
                    title={t('accounts')}
                  />
                )}
                {hasCustomerUsers && (
                  <GenericAdvancedFilterSection
                    filters={customerUsersFilters}
                    icon={<UsersThree />}
                    isLoading={customerUsersFiltersIsLoading}
                    title={t('users')}
                  />
                )}
              </>
            )}
          </IconContext.Provider>
        </DropdownMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  )
}

export default AddAdvancedFilterMenu
