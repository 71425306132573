import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useCollectionStore from '@/store/useCollectionStore'
import { useCallback, useMemo, useState } from 'react'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import { useQuery } from '@tanstack/react-query'
import { stringToDate } from '@/utils/date'
import { queryClient } from '@/plugins/reactQueryClient'
import { delay } from '@/utils/delay'
import useDidUpdateEffect from '../useDidUpdateEffect'
import useMetricListPayload from '../metrics/useMetricListPayload'
import { OpportunityRequests } from '@/types/opportunity'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityItemWithMetrics, OpportunityStatus } from '@/types/opportunity/Opportunity'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import useOpportunityFetchMetrics from './useOpportunityFetchMetrics'

const PAGE_SIZE = 10
const PROGRESS_STEP_SIZE = 100 / 3

export const OPPORTUNITIES_KEY_PREFIX = 'all-opportunities'

interface Params {
  enabled?: boolean
}

const defaultParams = {
  enabled: true
} satisfies Params

const useAllOpportunitiesQuery = ({ enabled = defaultParams.enabled }: Params = defaultParams) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'all-opportunities-query' })

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)

  const progress = useAreasAndOpportunitiesState(state => state.opportunitiesProgress)
  const setProgress = useAreasAndOpportunitiesState(state => state.setOpportunitiesProgress)
  const loadStep = useAreasAndOpportunitiesState(state => state.opportunitiesLoadStep)
  const setLoadStep = useAreasAndOpportunitiesState(state => state.setOpportunitiesLoadStep)

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const { areas } = useBasicAreaOfInterestQuery({
    collectionId: currentCollectionId,
    enabled: true
  })

  const { areas: allAreas } = useBasicAreaOfInterestQuery({
    enabled: currentCollection?.type === 'opportunity'
  })

  const { addShareFiltersToMetrics, getMetricList } = useMetricListPayload()

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(getMetricList({ includePreviousValue: false }))
  }, [getMetricList, addShareFiltersToMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  const queryKey = useMemo(
    () => [OPPORTUNITIES_KEY_PREFIX, { datePeriod, dateRange, searchText, currentCollectionId }],
    [datePeriod, dateRange, searchText, currentCollectionId]
  )

  const { fetchOppsSortingMetricsMutation, fetchOppsMetricsMutation } = useOpportunityFetchMetrics()

  const { fetchMetricsMutation, isMetricsLoading } = useMemo(() => {
    const { mutate: fetchMetricsMutation, isLoading: isMetricsLoading } = fetchOppsMetricsMutation

    return { fetchMetricsMutation, isMetricsLoading }
  }, [fetchOppsMetricsMutation])

  const fetchMetrics = useCallback(
    async (pageRange: [start: number, end: number]) => {
      if (loadStep !== 'done') {
        setLoadStep('calculating')
      }

      const loadedData = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      const opportunitiesToFetch = loadedData?.slice(pageRange[0], pageRange[1]) ?? []

      fetchMetricsMutation(
        { metricList, opps: opportunitiesToFetch, setProgress },
        {
          onSuccess: async data => {
            const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
            if (!prevOpps) return
            const newOpps = prevOpps.map((item, index) => {
              return {
                ...item,
                metrics:
                  index >= pageRange[0] && index < pageRange[1]
                    ? data[index - pageRange[0]]
                    : item.metrics
              }
            })

            queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
            setProgress(100)
            await delay(1000)
            setLoadStep('done')
          },
          onError: () => {
            addErrorToast({ text: 'Failed to fetch opportunities metrics.' })
          }
        }
      )
    },
    [fetchMetricsMutation, setLoadStep, loadStep, queryKey, metricList, setProgress, addErrorToast]
  )

  const { fetchSortingMetricsMutate, isSortingMetricsLoading } = useMemo(() => {
    const { mutate: fetchSortingMetricsMutate, isLoading: isSortingMetricsLoading } =
      fetchOppsSortingMetricsMutation

    return { fetchSortingMetricsMutate, isSortingMetricsLoading }
  }, [fetchOppsSortingMetricsMutation])

  const fetchSortingMetrics = useCallback(
    async (params: {
      opps: OpportunityItemWithMetrics[]
      sortColumn?: string | undefined
      sortDirection?: 'asc' | 'desc'
    }) => {
      const { opps, sortDirection, sortColumn } = params
      setLoadStep('ordering')
      setCurrentPage(0)
      setProgress(PROGRESS_STEP_SIZE)

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, prevOpps => {
        if (!prevOpps) return

        return prevOpps.map(item => ({
          ...item,
          metrics: []
        }))
      })

      fetchSortingMetricsMutate(
        { opps, sortColumn, sortDirection },
        {
          onSuccess: newOrderedOpps => {
            queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOrderedOpps)
            setProgress(PROGRESS_STEP_SIZE * 2)
            fetchMetrics([0, PAGE_SIZE])
          },
          onError: () => {
            const message = 'Failed to fetch sorting metrics.'
            addErrorToast({ text: message })
          }
        }
      )
    },
    [fetchSortingMetricsMutate, addErrorToast, fetchMetrics, setLoadStep, setProgress, queryKey]
  )

  const queryFn = async () => {
    setLoadStep('loading')
    setProgress(2)

    const searchParams: OpportunityRequests.SearchOpportunityParams = {
      limit: 1000,
      name: searchText
    }

    if (currentCollectionId) {
      searchParams.collection_id = currentCollectionId
    }

    const [error, response] = await OpportunityService.searchOpportunities(searchParams)

    if (error) {
      logException(error, { message: 'Failed to fetch opportunity list' })
      throw error
    }

    const currentAreas = currentCollection?.type === 'opportunity' ? allAreas : areas

    const data = response.opportunities
      .map(
        (filter): OpportunityItemWithMetrics => ({
          name: filter.name,
          id: filter.opportunity_id,
          status: filter.opportunity_status_id,
          createdAt: stringToDate(filter.created_at),
          metrics: [],
          new: filter.new ?? false,
          opportunityCount: 0,
          filterId: filter.filter_id,
          description: filter.description ?? '',
          createdBy: filter.created_by,
          relations: filter.areas,
          initiatives: filter.initiatives,
          area:
            filter.areas.length === 1
              ? currentAreas.find(area => area.id === filter.areas[0])
              : undefined,
          pinnedFeedback: filter.pinned_feedback ?? []
        })
      )
      .filter(opp => opp.status !== OpportunityStatus.Dismissed)

    fetchSortingMetrics({ opps: data, sortColumn: 'count:count', sortDirection: 'desc' })
    setProgress(PROGRESS_STEP_SIZE)

    return data
  }

  const { data, isLoading: isOpportunitiesLoading } = useQuery({
    queryKey,
    queryFn,
    enabled:
      enabled &&
      (currentCollection?.type === 'opportunity' ? allAreas.length > 0 : areas.length > 0)
  })

  const loadNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
    fetchMetrics([(currentPage + 1) * PAGE_SIZE, (currentPage + 2) * PAGE_SIZE])
  }

  const opportunities = useMemo(() => {
    let _opps: OpportunityItemWithMetrics[] = []

    if (!data) return _opps
    _opps = [...data]

    return _opps.slice(0, (currentPage + 1) * PAGE_SIZE)
  }, [data, currentPage])

  const hasMore = data && data.length > (currentPage + 1) * PAGE_SIZE

  useDidUpdateEffect(() => {
    setCurrentPage(0)
    fetchMetrics([0, PAGE_SIZE])
  }, [hiddenMetrics])

  return {
    opportunities,
    isLoading: isOpportunitiesLoading,
    isMetricsLoading,
    isSortingMetricsLoading,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    queryKey,
    allOpportunities: data ?? [],
    fetchSortingMetrics
  }
}

export default useAllOpportunitiesQuery
