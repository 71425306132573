import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'
import useCollectionStore from '@/store/useCollectionStore'
import useMetricListPayload from '../metrics/useMetricListPayload'
import { useMemo, useRef, useState } from 'react'
import { allMetricItems, getMetricByColumn } from '@/utils/metrics'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '@/plugins/reactQueryClient'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { endDateParam, startDateParam } from '@/utils/date'
import { MetricsRequests, RawMetric } from '@/types/metrics'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import MetricsService from '@/services/MetricsService'
import { delay } from '@/utils/delay'
import { AreaRequests } from '@/types/area'
import AreaService from '@/services/AreaService'
import useDidUpdateEffect from '../useDidUpdateEffect'
import { RawAreaError } from '@/types/area/AreaRequests'
import { INVALID_METRIC_TABLE_COLUMNS } from '@/utils/opportunityUtils'

const PAGE_SIZE = 10
const PROGRESS_STEP_SIZE = 100 / 3

export const AREAS_KEY_PREFIX = 'all-areas'

const NOT_METRIC_COLLUMN = ['', 'name', 'opportunityCount', 'status']

interface Params {
  enabled?: boolean
}

const defaultParams = {
  enabled: true
} satisfies Params

const useAllAreasQuery = ({ enabled = defaultParams.enabled }: Params = defaultParams) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'all-areas-query' })

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)

  const progress = useAreasAndOpportunitiesState(state => state.areasProgress)
  const setProgress = useAreasAndOpportunitiesState(state => state.setAreasProgress)
  const loadStep = useAreasAndOpportunitiesState(state => state.areasLoadStep)
  const setLoadStep = useAreasAndOpportunitiesState(state => state.setAreasLoadStep)

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange
        ? { start: startDateParam(state.dateRange.start), end: endDateParam(state.dateRange.end) }
        : null,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const startDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.start
    return undefined
  }, [datePeriod, dateRange])

  const endDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.end
    return undefined
  }, [datePeriod, dateRange])

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { isLoading: isSourcesLoading } = useSourcesQuery()

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const { addShareFiltersToMetrics, getMetricList } = useMetricListPayload()

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(getMetricList({ includePreviousValue: false }))
  }, [getMetricList, addShareFiltersToMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  const mapAreaErrorsById = useRef<Record<string, RawAreaError>>({})

  /**
   * since the org metrics are being returned from the endpoint when a error occurrs with the area
   * we set the area error on the metrics
   */
  const getRawMetricListWithAreaError = (areaId: string, rawMetrics: RawMetric[]) => {
    const areaError = mapAreaErrorsById.current[areaId]
    if (!areaError) return rawMetrics

    return rawMetrics.map(rawMetric => {
      return {
        ...rawMetric,
        error: {
          message: areaError.message,
          code: areaError.code,
          field: areaError.details?.field,
          isFromArea: true
        }
      } as RawMetric
    })
  }

  const queryKey = useMemo(
    () => [AREAS_KEY_PREFIX, { searchText, currentCollectionId }],
    [searchText, currentCollectionId]
  )

  const { mutate: fetchMetrics, isLoading: isMetricsLoading } = useMutation({
    mutationKey: ['fetch-areas-page-metrics', { datePeriod, dateRange }],
    mutationFn: async (pageRange: [number, number]) => {
      if (loadStep !== 'done') {
        setLoadStep('calculating')
      }

      const loadedData = queryClient.getQueryData<AreaOfInterestData[]>(queryKey)
      const areasToFetch = loadedData?.slice(pageRange[0], pageRange[1]) ?? []

      areasToFetch.forEach(area => {
        if (area.error) {
          mapAreaErrorsById.current[area.id] = area.error
        }
      })

      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: areasToFetch.map((area): FeedbackListQueryParams => {
          if (area.error) return {}

          return { context: area.context }
        }),
        metric_list: metricList,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      // simulate loading
      for (let i = 0; i < 6; i++) {
        setTimeout(() => {
          setProgress(2 * PROGRESS_STEP_SIZE + 5 * i)
        }, 1000 * i)
      }

      const [metricsError, metricsResponse] = await MetricsService.metrics(metricsPayload)
      if (metricsError) {
        logException(metricsError, { message: 'Failed to fetch final areas metrics' })
        throw metricsError
      }

      return metricsResponse
    },
    onSuccess: async (data, pageRange) => {
      const prevAreas = queryClient.getQueryData<AreaOfInterestData[]>(queryKey)
      if (!prevAreas) return

      const newAreas = prevAreas.map((item, index) => ({
        ...item,
        metrics: getRawMetricListWithAreaError(
          item.id,
          index >= pageRange[0] && index < pageRange[1] ? data[index - pageRange[0]] : item.metrics
        )
      }))

      queryClient.setQueryData<AreaOfInterestData[]>(queryKey, () => newAreas)
      setProgress(100)
      await delay(1000)
      setLoadStep('done')
    },
    onError: () => {
      addErrorToast({ text: 'Failed to fetch areas metrics.' })
    }
  })

  const getSortingMetric = (sortColumn?: string) => {
    const countMetric = allMetricItems.count.metric
    if (!sortColumn) return countMetric

    return INVALID_METRIC_TABLE_COLUMNS.includes(sortColumn)
      ? countMetric
      : getMetricByColumn(sortColumn)?.metric ?? countMetric
  }

  const { mutate: fetchSortingMetrics, isLoading: isSortingMetricsLoading } = useMutation({
    mutationKey: ['fetch-all-areas-sorting-metrics', { datePeriod, dateRange }],
    mutationFn: async (params: {
      areas: AreaOfInterestData[]
      sortColumn?: string | undefined
      sortDirection?: 'asc' | 'desc'
    }) => {
      const { areas, sortColumn } = params
      setLoadStep('ordering')

      const chunkSize = 25
      const chunks: AreaOfInterestData[][] = []

      areas.forEach(area => {
        if (area.error) {
          mapAreaErrorsById.current[area.id] = area.error
        }
      })

      for (let i = 0; i < areas.length; i += chunkSize) {
        chunks.push(areas.slice(i, i + chunkSize))
      }

      let completed = 0

      const sortingMetric = getSortingMetric(sortColumn)

      const promises = chunks.map(async (chunk, index) => {
        const metricsPayload: MetricsRequests.MetricsPayload = {
          filter_list: chunk.map((area): FeedbackListQueryParams => {
            if (area.error) return {}

            return { context: area.context }
          }),
          metric_list: [
            {
              ...sortingMetric,
              name: sortingMetric?.name ?? 'feedback_count',
              label: sortingMetric?.label ?? 'feedback_count',
              args: sortingMetric?.filter,
              include_previous_value: false
            }
          ],
          posted_at_gte: startDate,
          posted_at_lt: endDate
        }

        await delay(250 * index)
        return MetricsService.metrics(metricsPayload).then(result => {
          setProgress(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunks.length * 2)) * completed)
          completed++

          return result
        })
      })

      const responses = await Promise.all(promises)
      const someError = responses.find(response => response[0])
      if (someError) {
        logException(someError, { message: 'Failed to fetch sorting areas metrics' })
        throw someError
      }

      const data = responses.flatMap(response => response[1]) as MetricsRequests.MetricsResponse
      return data
    },
    onMutate: () => {
      setCurrentPage(0)
      setProgress(PROGRESS_STEP_SIZE)
      const prevAreas = queryClient.getQueryData<AreaOfInterestData[]>(queryKey)
      if (!prevAreas) return

      const newAreas = prevAreas.map(item => ({
        ...item,
        metrics: []
      }))

      queryClient.setQueryData<AreaOfInterestData[]>(queryKey, () => newAreas)
    },
    onSuccess: async (data, params) => {
      const { sortColumn, sortDirection } = params
      const prevAreas = queryClient.getQueryData<AreaOfInterestData[]>(queryKey)
      if (!prevAreas) return

      const newAreas = prevAreas.map((item, index) => ({
        ...item,
        metrics: getRawMetricListWithAreaError(item.id, data[index])
      }))

      if (NOT_METRIC_COLLUMN.includes(sortColumn ?? 'count:count')) {
        if (sortColumn === 'name') {
          newAreas.sort((a, b) =>
            sortDirection === 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
          )
        }
        if (sortColumn === 'opportunityCount') {
          newAreas.sort((a, b) =>
            sortDirection === 'desc'
              ? b.opportunityCount - a.opportunityCount
              : a.opportunityCount - b.opportunityCount
          )
        }
      } else {
        newAreas.sort((a, b) => a.name.localeCompare(b.name))
        newAreas.sort((a, b) =>
          sortDirection === 'desc'
            ? (b.metrics[0]?.current_value ?? 0) - (a.metrics[0]?.current_value ?? 0)
            : (a.metrics[0]?.current_value ?? 0) - (b.metrics[0]?.current_value ?? 0)
        )
      }

      queryClient.setQueryData<AreaOfInterestData[]>(queryKey, () => newAreas)
      setProgress(PROGRESS_STEP_SIZE * 2)
      fetchMetrics([0, PAGE_SIZE])
    }
  })

  const queryFn = async () => {
    setLoadStep('loading')
    setProgress(2)

    const searchParams: AreaRequests.SearchAreasParams = {
      limit: 1000,
      name: searchText,
      transform: true
    }

    if (currentCollectionId) {
      searchParams.collection_id = currentCollectionId
    }

    const [error, response] = await AreaService.searchAreas(searchParams)

    if (error) {
      logException(error, { message: 'Failed to fetch area list' })
      throw error
    }

    const data = response.areas.map(
      (data): AreaOfInterestData => ({
        id: data.area_id,
        filterId: data.filter_id,
        name: data.name,
        content: data.content ?? [],
        context: data.context,
        createdBy: data.created_by ?? '',
        opportunityCount: data.opportunities?.length ?? 0,
        metrics: [],
        opportunities: [],
        useInUnmappedArea: data.is_mapped ?? false,
        advanced: data.advanced ?? false,
        error: data.error || null
      })
    )

    fetchSortingMetrics({ areas: data, sortColumn: 'count:count', sortDirection: 'desc' })
    setProgress(PROGRESS_STEP_SIZE)

    return data
  }

  const { data, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled: enabled && !isSourcesLoading,
    retry: 1,
    retryDelay: 4000
  })

  const loadNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
    fetchMetrics([(currentPage + 1) * PAGE_SIZE, (currentPage + 2) * PAGE_SIZE])
  }

  const areas = useMemo(() => {
    let _areas: AreaOfInterestData[] = []

    if (!data) return _areas
    _areas = [...data]
    return _areas.slice(0, (currentPage + 1) * PAGE_SIZE)
  }, [data, currentPage])

  const hasMore = data && data.length > (currentPage + 1) * PAGE_SIZE

  useDidUpdateEffect(() => {
    setCurrentPage(0)
    fetchMetrics([0, PAGE_SIZE])
  }, [hiddenMetrics])

  return {
    areas,
    isMetricsLoading,
    isSortingMetricsLoading,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    queryKey,
    allAreas: data ?? [],
    fetchSortingMetrics,
    ...query
  }
}

export default useAllAreasQuery
